export async function apiRequest<T>(path: string, headers?: RequestInit): Promise<ApiDataResponse<T>> {
    const json: ApiDataResponse<T> = await (await fetch(path, headers)).json();

    if (!json.success) {
        console.debug(`Api endpoint ${path} came back with a failed request`);

        if (json.error) {
            console.error(json.error.message);
        }
        else {
            console.warn(json.message);
        }
    }

    return json;
}

export async function apiCall<T>(path: string, headers?: RequestInit): Promise<{resp: Response, data: T  }> {
    const resp = await fetch(path, headers);

    try {
        if (!resp.ok) {
            const message = ((JSON.parse(await resp.text())) as Error).message;
            console.error(resp);
            throw new Error(message);
        }

        const body = await resp.json();
        return {
            resp: resp,
            data: body
        }
    }
    catch(err) {
        throw err;
    }
}

export function enumToList<T extends object>(data: T): Array<keyof typeof data> {
    return Object.keys(data).filter(d => isNaN(Number(d))) as Array<keyof typeof data>;
}

export function isType<T>(o: any, toTest: string): o is T {
    return toTest in o;
}